import classNames from "classnames";
import React from "react";
import ContactIMG from '../assets/images/email.jpg';
import { SectionProps } from "../utils/SectionProps";
const propTypes = {
    ...SectionProps.types,
};

const defaultProps = {
    ...SectionProps.defaults,
};

const Contact = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {
    // const [videoModalActive, setVideomodalactive] = useState(false);

    // const openModal = (e) => {
    //     e.preventDefault();
    //     setVideomodalactive(true);
    // };

    // const closeModal = (e) => {
    //     e.preventDefault();
    //     setVideomodalactive(false);
    // };

    const outerClasses = classNames(
        "hero section",
        topOuterDivider && "has-top-divider",
        bottomOuterDivider && "has-bottom-divider",
        hasBgColor && "has-bg-color",
        invertColor && "invert-color",
        className
    );

    const innerClasses = classNames(
        "hero-inner section-inner",
        topDivider && "has-top-divider",
        bottomDivider && "has-bottom-divider"
    );

    return (
        <section {...props} className={outerClasses}>
            <div className="container-sm">
                <div className={innerClasses}>
                    <img src={ContactIMG} alt="sowebmail contact us" />
                    <h4>Contact us</h4>
                    <p></p>
                    <p style={{ fontSize: 16 }}>
                        If you have any questions or suggestions about Webamil, do not hesitate to contact us at <b> info@sowebmail.com.</b>
                    </p>
                </div>
            </div>
        </section>
    );
};

Contact.propTypes = propTypes;
Contact.defaultProps = defaultProps;

export default Contact;
