import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "Do more with AI",
    paragraph:
      "Experience the future of email productivity with our AI-powered app. Do more with AI as it simplifies your email management, suggesting responses, summarizing messages, and even composing drafts. Upgrade your email experience today.",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Dont write any prompt to generate email
                </div>
                <h3 className="mt-0 mb-12">AI powered compose</h3>
                <p className="m-0">
                  Introducing our AI-powered Smart Compose feature! Say goodbye to the hassle of composing meeting requests and greeting emails from scratch. With our innovative technology, you can now breeze through your daily correspondence by simply filling out a simple form. <br /><br />Save time and enhance your communication efficiency with our AI-powered Smart Compose. No more writer's block or staring at a blank screen. Just input the necessary details, and our intelligent algorithm will do the rest.

                  Experience the power of AI in your daily communications and elevate your email game to new heights. Try our Smart Compose feature today and see the difference it can make in simplifying your professional life.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/compose.gif")}
                  alt="Features split 01"
                  style={{ width: 300, height: "auto" }}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Just reply with single tap of button
                </div>
                <h3 className="mt-0 mb-12">AI Assist reply</h3>
                <p className="m-0">
                  Introducing our AI Assisted Smart Reply feature, designed to simplify your email communication like never before. With this innovative technology, you can now quickly reply to simple emails, acknowledge messages, and send thank-you notes with just one click.
                  <br /><br />
                  Our intelligent system analyzes the context of your message and generates personalized responses tailored to your needs. With AI Assisted Smart Reply, you no longer have to waste time crafting emails from scratch. Instead, simply choose one of our suggested responses and send it off in seconds.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/reply.gif")}
                  alt="Features split 02"
                  style={{ width: 300, height: "auto" }}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Build summary in just simple click
                </div>
                <h3 className="mt-0 mb-12">Generate summary</h3>
                <p className="m-0">
                  Our intelligent AI system analyzes the content of your email and generates bullet points that capture the key information. No more sifting through dense paragraphs or lengthy threads. With AI Assisted Smart Summary, you can instantly extract the most important details and get to the heart of the matter.
                  <br /><br />
                  Whether you're dealing with a lengthy report, meeting minutes, or a detailed proposal, our AI technology will save you time and effort by condensing the information into a clear and digestible format. You can trust our Smart Summary feature to accurately capture the main points, allowing you to quickly grasp the essence of the email.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/summary.gif")}
                  alt="Features split 03"
                  style={{ width: 300, height: "auto" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
